import "../index.css"

const BehaviorComponent = ({ id, behaviorClass, behavior, label, onSelect }) => {
    return (
      <div
        id={id}
        className={`behavior ${behaviorClass}`}
        behavior={behavior}
        onClick={() => onSelect(id,behavior)}
      >
        {label}
      </div>
    );
  };

  export default BehaviorComponent