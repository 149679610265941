import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import Plot from "./components/Plot";
import ResultsTable from "./components/ResultsTable";
import Behavior from "./components/Behavior";

function App() {
  const [selectedBehavior, setSelectedBehavior] = React.useState(null);
  const [selectedCircle, setSelectedCircle] = React.useState(null);
  const [selectedDirection, setSelectedDirection] = React.useState(null);
  const [selectedInitiative, setSelectedInitiative] = React.useState(null);
  const [selectedTime, setSelectedTime] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);


  const handleBehaviorSelect = (behaviorId, behavior, ) => {
    if (selectedBehavior === behaviorId) {
      setSelectedBehavior(null);
      // Add 'selected' class to the selected circle
      const selectedBehaviorElement = document.getElementById(behaviorId);
      if (selectedBehaviorElement) {
        selectedBehaviorElement.classList.remove('selected');
      }
      const allBehaviorElement = document.getElementById('all');
      if (allBehaviorElement) {
        allBehaviorElement.classList.add('selected');
      }
    } else {
      setSelectedBehavior(behaviorId); // Set the selected circle ID
      // Update the className of the selected circle
      document.querySelectorAll('.behavior').forEach(button => {
        button.classList.remove('selected');
      });
      // Add 'selected' class to the selected circle
      const selectedBehaviorElement = document.getElementById(behaviorId);
      if (selectedBehaviorElement) {
        selectedBehaviorElement.classList.add('selected');
      }
      if (behaviorId === 'all') {
        setSelectedBehavior(null)
      } else {
        setSelectedBehavior(behavior)
      }
    }
  };

  const handleCircleSelect = (circleId, direction, initiative,time) => {
      // remove any selected axes
      document.querySelectorAll('.axis').forEach(axis => {
        axis.classList.remove('selected');
      });


      if (selectedCircle === circleId) {
        setSelectedCircle(null);
        // Add 'selected' class to the selected circle
        const selectedCircleElement = document.getElementById(circleId);
        if (selectedCircleElement) {
            selectedCircleElement.classList.remove('selected');
        }
        setSelectedDirection(null)
        setSelectedInitiative(null)
        setSelectedTime(null)
      } else {
        setSelectedCircle(circleId); // Set the selected circle ID
        // Update the className of the selected circle
        document.querySelectorAll('.circle').forEach(circle => {
          circle.classList.remove('selected');
        });
        // Add 'selected' class to the selected circle
        const selectedCircleElement = document.getElementById(circleId);
        if (selectedCircleElement) {
            selectedCircleElement.classList.add('selected');
        }
        setSelectedDirection(direction)
        setSelectedInitiative(initiative)
        setSelectedTime(time)
      }
    };

    const handleAxisSelect = (axisId, axisClass,direction,initiative,time) => {
      // remove any selected circles
    document.querySelectorAll('.circle').forEach(circle => {
      circle.classList.remove('selected');
    });

      switch (axisClass) {
        case 'axis label top':
          console.log('Case: top');
          if (time === selectedTime) {
            // Add 'selected' class to the selected circle
            const selectedAxisElement = document.getElementById(axisId);
            if (selectedAxisElement) {
              selectedAxisElement.classList.remove('selected');
            }
            setSelectedTime(null)
          } else {
            setSelectedTime(time);
            console.log(axisClass)
            Array.from(document.getElementsByClassName(axisClass)).forEach(axis => {
              axis.classList.remove('selected');
            });
            // Add 'selected' class to the selected axis
            const selectedAxisElement = document.getElementById(axisId);
            if (selectedAxisElement) {
              selectedAxisElement.classList.add('selected');
            }
          }
          break;
        case 'axis label left':
          console.log('Case: left');
          if (direction === selectedDirection) {
            // Add 'selected' class to the selected circle
            const selectedAxisElement = document.getElementById(axisId);
            if (selectedAxisElement) {
              selectedAxisElement.classList.remove('selected');
            }
            setSelectedDirection(null)
          } else {
            setSelectedDirection(direction);
            console.log(axisClass)
            Array.from(document.getElementsByClassName(axisClass)).forEach(axis => {
              axis.classList.remove('selected');
            });
            // Add 'selected' class to the selected axis
            const selectedAxisElement = document.getElementById(axisId);
            if (selectedAxisElement) {
              selectedAxisElement.classList.add('selected');
            }
          }
          break;
          case 'axis label bottom':
            console.log('Case: bottom');
            if (initiative === selectedInitiative) {
              // Add 'selected' class to the selected circle
              const selectedAxisElement = document.getElementById(axisId);
              if (selectedAxisElement) {
                selectedAxisElement.classList.remove('selected');
              }
              setSelectedInitiative(null)
            } else {
              setSelectedInitiative(initiative);
              console.log(axisClass)
              Array.from(document.getElementsByClassName(axisClass)).forEach(axis => {
                axis.classList.remove('selected');
              });
              // Add 'selected' class to the selected axis
              const selectedAxisElement = document.getElementById(axisId);
              if (selectedAxisElement) {
                selectedAxisElement.classList.add('selected');
              }
            }
            break;
        default:
          console.log('Case: managed to get the default');
      }
    };

  

  useEffect(() => {
    // This code will run whenever tableData changes
    // setDataTable(tableData)
    // Fetch and filter data based on the selected circle's ID
    d3.csv('/data-for-plot.csv').then((data) => {
      const filteredData = data.filter((item) => {
          // Check if the filter variables are not null, and filter accordingly
          if (selectedBehavior !== null) {
            // Split the Direction column value by comma and check if any of the split values match
            const behaviors = item.Behavior.split(',').map(val => val.trim());
            console.log(behaviors);
            if (!behaviors.includes(selectedBehavior)) {
                return false; // Exclude the item if it doesn't match the filter
            }
        }
          if (selectedDirection !== null) {
              // Split the Direction column value by comma and check if any of the split values match
              const directions = item.Direction.split(',').map(val => val.trim());
              if (!directions.includes(selectedDirection)) {
                  return false; // Exclude the item if it doesn't match the filter
              }
          }
          if (selectedInitiative !== null) {
              // Split the Initiative column value by comma and check if any of the split values match
              const initiatives = item.Initiative.split(',').map(val => val.trim());
              if (!initiatives.includes(selectedInitiative)) {
                  return false; // Exclude the item if it doesn't match the filter
              }
          }
          if (selectedTime !== null) {
              // Split the Time column value by comma and check if any of the split values match
              const times = item.Time.split(',').map(val => val.trim())
              console.log(times);
              if (!times.includes(selectedTime)) {
                  return false; // Exclude the item if it doesn't match the filter
              }
          }
          return true; // Include the item if it passes all filter checks
      });
      setTableData(filteredData);
  }).catch((error) => {
      console.error('Error fetching data:', error);
  });
  
}, [selectedBehavior, selectedDirection, selectedInitiative, selectedTime]); // Specify selectedDataRows as a dependency



const defaultValues = [{
  Title: "Title",
  Authors: "Authors",
  Year: "Year",
  DOI: "DOI",
  BCT: "Technique",
  Link: "Link"
}]


const [dataTable, setDataTable] = useState(defaultValues)


useEffect(() => {
    // This code will run whenever tableData changes
    // setDataTable(tableData)
    setDataTable(tableData);
  
}, [tableData]); // Specify selectedDataRows as a dependency


  return (
      <body>
        <div className="area">
          <div className="container">
          <h1>Behavior Change Design</h1>
          <p className="message">This tool is intended to help technology designers create behavior change technologies. By selecting combinations of important design variables, the user can discover projects where researchers implemented the selected approach, along with the associated behavior change techniques.</p>
          <h2>Step 1: Target Behavior</h2>
              <p className="message">You can (optionally) select a target behavior to filter the results.</p>
          <Behavior handleBehaviorSelect={handleBehaviorSelect}></Behavior>
          <h2>Step 2: Design Variables</h2>
              <p className="message">You can select the direction of desired behavior change, the level of initiative of the technology, and the time of intervention to filter the results. Click on axis labels, circles, or combinations.</p>
          <Plot handleCircleSelect={handleCircleSelect} handleAxisSelect={handleAxisSelect}></Plot>
          </div>
          <svg className="design" preserveAspectRatio="none" viewBox="0 0 1320 235" width="1320" height="235" xmlns="http://www.w3.org/2000/svg"><path d="M1320,0c-177,0.3-385.1,41.199-624.199,122.8C456.7,204.399,224.7,235.8,0,216.899v17.2h1320V0z"></path></svg>
        </div>
        <div className="container">
        <ResultsTable data={dataTable}></ResultsTable>
        </div>
      </body>
  );
}

export default App;