import "../index.css"

const AxisLabelComponent = ({ id, axisClass, label, direction, initiative, time, onSelect }) => {
    return (
      <div
        id={id}
        className={axisClass}
        direction={direction}
        initiative={initiative}
        time={time}
        onClick={() => onSelect(id,axisClass,direction,initiative,time)}
      >
        {label}
      </div>
    );
  };

  export default AxisLabelComponent