import React from "react";
import "../index.css"
import CircleComponent from "./CircleComponent";
import AxisLabelComponent from "./AxisLabelComponent";

const Plot = ({handleCircleSelect, handleAxisSelect}) => {
    

    return (
        <div className="row">
            <div className="container">
                <div className="spacer title">
                    42
                </div>
                <div className="spacer label top">
                    42
                </div>
                <div className="row" style={{height: '100%'}}>
                    <div className="axis title">
                        Direction<br/>of<br/>Change
                    </div>
                    <div className="axis labels vertical">
                        <AxisLabelComponent 
                            id={'increase-desired'} 
                            axisClass={'axis label left'} 
                            label={'Increase Desired'} 
                            direction={'increase'} 
                            initiative={null} 
                            time={null} 
                            onSelect={handleAxisSelect}
                        />
                        <AxisLabelComponent 
                            id={'decrease-undesired'} 
                            axisClass={'axis label left'} 
                            label={'Decrease Undesired'} 
                            direction={'decrease'} 
                            initiative={null} 
                            time={null} 
                            onSelect={handleAxisSelect}
                        />
                    </div>
                </div>
                <div className="spacer label bottom">
                    42
                </div>
                <div className="spacer title">
                    42
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="axis title">
                        Time
                    </div>
                </div>
                <div className="row" style={{width:'100%', flexGrow: 1}}>
                    <div className="axis labels horizontal" >
                    <AxisLabelComponent 
                            id={'active-before'} 
                            axisClass={'axis label top'} 
                            label={'Before'} 
                            direction={null} 
                            initiative={null} 
                            time={'before'} 
                            onSelect={handleAxisSelect}
                    />
                    <AxisLabelComponent 
                            id={'active-during'} 
                            axisClass={'axis label top'} 
                            label={'During'} 
                            direction={null} 
                            initiative={null} 
                            time={'during'} 
                            onSelect={handleAxisSelect}
                    />
                    <AxisLabelComponent 
                            id={'active-after'} 
                            axisClass={'axis label top'} 
                            label={'After'} 
                            direction={null} 
                            initiative={null} 
                            time={'after'} 
                            onSelect={handleAxisSelect}
                    />
                    </div>
                    <div className="axis labels horizontal">
                        <AxisLabelComponent 
                                id={'passive-before'} 
                                axisClass={'axis label top'} 
                                label={'Before'} 
                                direction={null} 
                                initiative={null} 
                                time={'before'} 
                                onSelect={handleAxisSelect}
                        />
                        <AxisLabelComponent 
                                id={'passive-during'} 
                                axisClass={'axis label top'} 
                                label={'During'} 
                                direction={null} 
                                initiative={null} 
                                time={'during'} 
                                onSelect={handleAxisSelect}
                        />
                        <AxisLabelComponent 
                                id={'passive-after'} 
                                axisClass={'axis label top'} 
                                label={'After'} 
                                direction={null} 
                                initiative={null} 
                                time={'after'} 
                                onSelect={handleAxisSelect}
                        />
                    </div>
                </div>
                <div className="plot wrapper">
                    <div className="row">
                        {[
                            {direction: 'increase', initiative: 'active', time: 'before', label: "Active Initiation Trigger"},
                            {direction: 'increase', initiative: 'active', time: 'during', label:"Active Progression Lubricatoin / Exit Friction"},
                            {direction: 'increase', initiative: 'active', time: 'after', label:"Active Reflection Trigger"},
                            {direction: 'increase', initiative: 'passive', time: 'before', label:"Passive Initiation Trigger"},
                            {direction: 'increase', initiative: 'passive', time: 'during', label:"Passive Progression Lubrication / Exit Friction"},
                            {direction: 'increase', initiative: 'passive', time: 'after', label:"Passive Reflection Trigger"}
                        ].map(({direction, initiative, time, label}) => {
                            const id = `${direction}-${initiative}-${time}`;
                            const circleClass = `${direction}-${initiative}`;
                            return (
                                <CircleComponent key={id} id={id} circleClass={circleClass} label={label}
                                direction={direction} initiative={initiative} time={time} onSelect={handleCircleSelect}
                                />
                            );
                        })}
                    </div>
                    <div className="row">
                    {[
                            {direction: 'decrease', initiative: 'active', time: 'before', label:"Initiation Friction"},
                            {direction: 'decrease', initiative: 'active', time: 'during', label:"Active Progression Friction / Exit Lubrication"},
                            {direction: 'decrease', initiative: 'active', time: 'after', label:"Active Reflection Trigger"},
                            {direction: 'decrease', initiative: 'passive', time: 'before', label:"Initiation Avoidance"},
                            {direction: 'decrease', initiative: 'passive', time: 'during', label:"Passive Progression Friction / Exit Lubrication"},
                            {direction: 'decrease', initiative: 'passive', time: 'after', label:"Passive Reflection Trigger"}
                        ].map(({direction, initiative, time, label}) => {
                            const id = `${direction}-${initiative}-${time}`;
                            const circleClass = `${direction}-${initiative}`;
                            return (
                                <CircleComponent key={id} id={id} circleClass={circleClass} label={label}
                                direction={direction} initiative={initiative} time={time} onSelect={handleCircleSelect}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="row" style={{width:'100%', flexGrow: 1}}>
                    <div className="axis labels horizontal" >
                        <AxisLabelComponent 
                                id={'active'} 
                                axisClass={'axis label bottom'} 
                                label={'Active'} 
                                direction={null} 
                                initiative={'active'} 
                                time={null} 
                                onSelect={handleAxisSelect}
                        />
                        <AxisLabelComponent 
                                id={'passive'} 
                                axisClass={'axis label bottom'} 
                                label={'Passive'} 
                                direction={null} 
                                initiative={'passive'} 
                                time={null} 
                                onSelect={handleAxisSelect}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="axis title">
                    Technology Initiative
                    </div>
                </div>
                
            </div>
            <div className="spacer label left">
                    Increase Desired
                </div>
                <div className="spacer title">
                Direction<br/>of<br/>Change
                </div>
        </div>
    )
};

export default Plot;