import React from "react";
import "../index.css"
import BehaviorComponent from "./BehaviorComponent";

const Behavior = ({handleBehaviorSelect}) => {
    

    return (
        <div className="row">
                    <div className="row">
                        {[
                            {behavior: 'all', label: 'All'},
                            {behavior: 'move', label: 'Move'},
                            {behavior: 'eat', label: 'Eat'},
                            {behavior: 'engage', label: 'Engage'},
                            {behavior: 'cogitate', label: 'Cogitate'},
                            {behavior: 'sleep', label: 'Sleep'},
                        ].map(({behavior, label}) => {
                            const id = behavior;
                            const behaviorClass = `button ${behavior}`;
                            return (
                                <BehaviorComponent 
                                key={id} 
                                id={id} 
                                behaviorClass={behaviorClass} 
                                label={label}
                                behavior={behavior} 
                                onSelect={handleBehaviorSelect}
                                />
                            );
                        })}
                    </div>
        </div>
    )
};

export default Behavior;