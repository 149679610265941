import React from 'react';

const ResultsTable = ({data}) => {

  if (data.length === 0) {
    return (
      <div className='table'>
        <caption>
          Related Projects
        </caption>
        <table className="resulting-table blank">
          <thead>
              <tr>
                  <th width={"30%"} >Papers</th>
                  <th width={"5%"}>Authors</th>
                  <th width={"5%"}>Year</th>
                  <th width={"20%"}>Behavior Change Techniques</th>
              </tr>
          </thead>
          <tbody id="table-body">
              <tr>
                <td>No data</td>
                <td>-</td>
                <td>-</td>
                <td>Try another combination</td>
              </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className='table'>
      <caption>
          Related Projects
        </caption>
      <table className="resulting-table">
        <thead>
            <tr>
                <th width={"30%"}>Papers</th>
                <th width={"5%"}>Authors</th>
                <th width={"5%"}>Year</th>
                <th width={"20%"}>Behavior Change Techniques</th>
            </tr>
        </thead>
        <tbody id="table-body">
          {data.map((item, index) => (
            <tr key={index} onClick={() => window.open(item.Link, '_blank')}>
              <td>{item.Title}</td>
              <td>{item.Name}</td>
              <td>{item.Year}</td>
              <td>{item.BCT}</td>
            </tr>
          ))}
        </tbody>
    </table>
    </div>
  );
};

export default ResultsTable;