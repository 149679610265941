import "../index.css"

const CircleComponent = ({ id, circleClass, label, direction, initiative, time, onSelect }) => {
    return (
      <div
        id={id}
        className={`circle ${circleClass}`}
        direction={direction}
        initiative={initiative}
        time={time}
        onClick={() => onSelect(id,direction,initiative,time)}
      >
        {label}
      </div>
    );
  };

  export default CircleComponent